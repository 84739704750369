<template>
  <div class="page">
    <div v-for="match in matchList" :key="match.key">
      <div class="demand color-m" v-if="match.result === 'Y'">
        <van-row class="title">
          <van-col span="16">{{ match.name }}：{{ match.content }} </van-col>
          <van-col span="8"
            ><van-tag :color="COLOR_M"
              ><van-icon name="passed" /> 符合条件</van-tag
            ></van-col
          >
        </van-row>
        <van-row class="condition">
          <van-col span="24"
            >条件要求：<span v-if="match.mode === 'UMT'">无限制</span>
            <span v-else-if="match.mode === 'NTN'">不为空</span>
            <span v-else>{{ match.condition }}</span></van-col
          >
        </van-row>
      </div>
      <div
        class="demand color-s1"
        v-if="
          match.result === 'N' && (match.mode === 'NTN' || match.mode === 'REQ')
        "
      >
        <van-row class="title">
          <van-col span="20"
            >{{ match.name }}：{{ match.content }}
            <van-tag type="primary" :color="COLOR_S1"
              ><van-icon name="close" /> 不符合要求条件</van-tag
            ></van-col
          >
          <van-col span="4">
            <van-tag plain :color="COLOR_S1"
              ><van-icon name="edit" @click="edit(match.group)" /> 修改</van-tag
            >
          </van-col>
        </van-row>
        <van-row class="condition">
          <van-col span="24"
            >条件要求：<span v-if="match.mode === 'NTN'">不为空</span
            ><span v-else>{{ match.condition }}</span></van-col
          >
        </van-row>
      </div>
      <div
        class="demand color-s2"
        v-if="match.result === 'N' && match.mode === 'MDR'"
      >
        <van-row class="title">
          <van-col span="20"
            >{{ match.name }}：{{ match.content }}
            <van-tag type="primary" :color="COLOR_S2"
              ><van-icon name="close" /> 不符合强制条件</van-tag
            ></van-col
          >
          <van-col span="4"
            ><van-tag plain :color="COLOR_S2"
              ><van-icon name="edit" @click="edit(match.group)" /> 修改</van-tag
            ></van-col
          >
        </van-row>
        <van-row class="condition">
          <van-col span="24">条件要求：{{ match.condition }}</van-col>
        </van-row>
      </div>
    </div>
    <div v-if="matchList.length <= 0" class="norecord">
      <van-icon name="info-o" /> 任务未设置条件
    </div>
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Tag, Icon } from 'vant'
import Loading from '../../common/Loading.vue'
export default {
  components: {
    Loading: Loading,
    [Tag.name]: Tag,
    [Icon.name]: Icon
  },
  data () {
    return {
      matchList: [],
      loadingShow: false
    }
  },
  mounted () {
  },
  methods: {
    async retrieveMatchList (personCode, taskCode) {
      this.loadingShow = true
      var pd = { personCode: personCode, taskCode: taskCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/member/person/retrievePersonMatchDetail', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.matchList = res.data
      }
      this.loadingShow = false
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  ::v-deep .van-notice-bar {
    font-size: 14px;
  }
  .demand {
    margin: 10px;
    border: 1px solid #eee;
    border-radius: 5px;
    text-align: left;
    .title {
      padding-left: 10px;
      font-weight: 600;
      height: 30px;
      line-height: 30px;
      border-bottom: 1px solid #eee;
      .result {
        text-align: center;
      }
    }
    .condition {
      padding: 10px 10px;
      line-height: 20px;
    }
  }
  .norecord {
    text-align: center;
    margin-top: 50px;
    font-size: 14px;
  }
}
</style>
